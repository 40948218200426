import React, { useState } from "react";
import footerLogo from "../../../../assets/footerLogo.svg";
import linkedinLogo from "../../../../assets/linkedinLogo.svg";
import youtubeLogo from "../../../../assets/youtubeLogo.svg";
import { ArrowRightIcon } from "../../../../assets/svg";
import jsonp from "jsonp";
import androidButton from "../../../../assets/androidButton.svg";
import iosButton from "../../../../assets/iosButton.svg";

const Footer = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isHovered, setIsHovered] = useState<boolean>(false);

  // Simulating an array of existing emails
  const existingEmails = ["test1@example.com", "test2@example.com"];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    if (!email) {
      setError("Please enter your email.");
      setSuccessMessage("");
      return;
    }

    if (existingEmails.includes(email)) {
      setError("This email already exists. Please use a different email.");
      setSuccessMessage("");
      return;
    }

    setError("");

    const url =
      "https://emeralddata.us13.list-manage.com/subscribe/post?u=af2935c91e17b5478cb4670d8&amp;id=e1bb4310cc&amp;f_id=002fbbe1f0";

    jsonp(
      `${url}&EMAIL=${email}`,
      { param: "c" },
      (error: any, data: { msg: string }) => {}
    );

    setSuccessMessage("Thank you for joining our wishlist!");
    setEmail("");
  };

  const footerItems = [
    {
      title: "Quick Links",
      items: [
        { name: "Why Emerald?", link: "#eds" },
        { name: "Pricing", link: "/Pricing" },
        { name: "Terms and Conditions", link: "/terms" },
        { name: "Privacy Policy", link: "/policy" },
      ],
    },
    {
      title: "Solution",
      items: [
        { name: "Asset Management", link: "#asset" },
        { name: "Site Management", link: "#site" },
        { name: "Vendor Management", link: "#vendor" },
        { name: "Purchase Orders", link: "#purchase" },
        { name: "Work Orders", link: "#work" },
        { name: "Reporting", link: "#reporting" },
      ],
    },
    {
      title: "Use Case",
      items: [
        {
          name: "Maintenance Management Software",
          link: "/",
        },
        {
          name: "Inventory Management Software",
          link: "/",
        },
        {
          name: "Vendor Management Software",
          link: "/",
        },
        { name: "Forecasting", link: "/" },
      ],
    },
  ];

  return (
    <div className="bg-black  lg:pt-14 text-white" id="footer">
      <div className="container">
        <div className="flex justify-between flex-wrap">
          <div className="">
            <div className="lg:mt-0 mt-12 ">
              <img src={footerLogo} alt="footer-logo" />
              <p className="text-sm font-normal mt-6 font-inter max-w-[18rem] w-full text-[#B5B5B5] ">
                Emerald Data Solutions, LLC <br /> 6715 Portland Road Sandusky,
                OH 44870
              </p>
            </div>

            <div className="mt-10">
              <p className="text-base font-medium lg:leading-5 font-inter ">
                Join Wishlist
              </p>
              <p className="text-sm font-normal leading-5 font-inter text-[#B5B5B5] mt-2">
                Be the First to Experience Emerald!
              </p>
              <form onSubmit={handleSubmit}>
                <div className="flex gap-2 items-center mt-4">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="w-[15rem] bg-black rounded-lg border-[1px] border-[#8f8f8f] py-2 px-4 font-inter text-white placeholder:text-white focus:ring-0 focus:ring-inset focus:outline-none hover:border-white hover:bg-[#eff0f321] focus:border-white sm:text-sm sm:leading-6"
                  />
                  <button
                    type="submit"
                    className="bg-white px-3 py-3 rounded-xl hover:bg-green-500 transition-all duration-200"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <ArrowRightIcon color={isHovered ? "white" : "black"} />
                  </button>
                </div>
                {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
                {successMessage && (
                  <p className="text-green-600 text-sm mt-2">
                    {successMessage}
                  </p>
                )}
              </form>
            </div>

            <div className="flex  gap-3 mt-10">
              <a href="https://www.linkedin.com/company/emerald-data-solutions-llc/about/">
                <img src={linkedinLogo} alt="linkedin-logo" />
              </a>
              <a href="/">
                <img src={youtubeLogo} alt="linkedin-logo" />
              </a>
            </div>
          </div>

          <div className="flex justify-between lg:flex-row flex-col lg:mt-0 mt-14">
            <div className="flex justify-between  md:flex-row flex-col md:flex-wrap gap-16">
              {footerItems.map((section, index) => {
                return (
                  <div className="flex flex-col justify-between " key={index}>
                    <div key={index}>
                      <h3 className="text-base font-medium leading-5 font-inter">
                        {section.title}
                      </h3>
                      <ul className="mt-6 flex flex-col gap-4 ">
                        {section.items.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <a
                              href={item.link}
                              className="text-sm font-normal text-[#B5B5B5] font-inter"
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {section.title === "Use Case" && (
                      <div className=" flex gap-3">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.emeralddata.emerald&pli=1"
                          target="_blank"
                        >
                          <img
                            src={androidButton}
                            alt="android-download-button"
                          />
                        </a>
                        <a
                          href="https://apps.apple.com/us/app/emerald-data-solutions/id6739134707"
                          target="_blank"
                        >
                          <img src={iosButton} alt="android-download-button" />
                        </a>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className="mt-8 w-full h-[0.0625rem]"
          style={{
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #EEEEF0 50%, rgba(239, 239, 241, 0.00) 100%)",
          }}
        ></div>

        <div className="mt-6 flex justify-between pb-6 flex-wrap gap-3">
          <p className="text-sm font-normal font-inter">
            support@emeralddata.io
          </p>
          <p className="text-sm font-normal font-inter">
            © 2024 Emerald Data Solutions. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
