import { motion } from "framer-motion";
const TermsAndConditions = () => {
  return (
    <div className="w-full md:py-[8rem] py-12 mb-12 lg:mb-0 text-center lg:text-left container flex flex-col items-center lg:flex-row ">
      <div className="w-full">
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="w-full"
        >
          <h1 className="md:text-5xl text-xl text-center leading-7 font-semibold md:leading-9 text-gray-900  w-full font-inter">
            EMERALD DATA SOLUTIONS <br />
          </h1>
          <div className="max-w-4xl mx-auto px-4 py-8">
            {/* <h1 className="text-3xl font-bold text-center mb-6">
              EMERALD DATA SOLUTIONS
            </h1> */}

            <h2 className="text-2xl font-bold text-center mb-8">
              TERMS AND CONDITIONS
            </h2>

            <p className="text-sm text-gray-600 mb-6">
              Last Updated: {"04/03/2025"}
            </p>

            <div className="space-y-6">
              <div className="bg-yellow-50 p-4 border-l-4 border-yellow-400">
                <p className="font-bold mb-4">
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING
                  OR USING OUR WEBSITES, MOBILE APPLICATIONS, OR OTHER SERVICES,
                  YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL
                  TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF
                  THESE TERMS AND CONDITIONS, YOU MAY NOT USE OUR SERVICES.
                </p>
              </div>

              <p className="text-gray-700">
                These Terms and Conditions govern your access to and use of the
                websites, mobile applications, and other products or services
                (collectively, the "Services") provided by{" "}
                <span className="font-semibold">Emerald Data Solutions</span>{" "}
                ("Emerald Data Solutions," "we," "us," or "our"). These Terms
                and Conditions apply to all Users of our Services within the
                United States. Our Services are{" "}
                <span className="font-bold">not</span> intended for use within
                the European Union or Europe.
              </p>

              <p className="text-gray-700">
                If you are using the Services on behalf of an organization or
                other entity, you represent and warrant that you are authorized
                to accept these Terms and Conditions on that entity's behalf and
                that the entity agrees to be responsible for your compliance
                with these Terms and Conditions.
              </p>

              <p className="text-gray-700">
                These Terms and Conditions do not modify any other agreements
                you may have with Emerald Data Solutions regarding specific
                products or services.
              </p>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">1. PRIVACY POLICY</h2>
                <p className="text-gray-700">
                  Please review our{" "}
                  <a
                    href="/policy"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    Privacy Policy
                  </a>{" "}
                  for information on how we collect, use, and disclose
                  information about our Users. Your use of the Services
                  constitutes your agreement to the collection and use of your
                  information as outlined in the Privacy Policy.
                </p>
              </section>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">2. DEFINITIONS</h2>
                <p className="text-gray-700 mb-4">
                  For the purposes of these Terms and Conditions:
                </p>
                <ul className="list-none space-y-4 pl-4">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      <span className="font-semibold">User</span> means any
                      individual or entity that accesses or uses the Services.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      <span className="font-semibold">Customer</span> means an
                      entity that purchases a subscription to the Services.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      <span className="font-semibold">Software</span> means the
                      cloud-based computerized maintenance management system
                      provided by Emerald Data Solutions.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <span>
                      <span className="font-semibold">App</span> means the
                      mobile application available for download on Google Play
                      and the Apple App Store.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(e)</span>
                    <span>
                      <span className="font-semibold">
                        Third-Party Services
                      </span>{" "}
                      means external platforms integrated with the Services,
                      including but not limited to Stripe, QuickBooks,
                      ProgressBook, PowerSchool, and Google Analytics.
                    </span>
                  </li>
                </ul>
              </section>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">
                  3. LICENSE AND ACCEPTABLE USE
                </h2>

                <p className="text-gray-700 mb-6">
                  Emerald Data Solutions grants the User a{" "}
                  <span className="font-semibold">
                    limited, non-exclusive, non-transferable, and revocable
                  </span>{" "}
                  license to access and use the Services, including the Software
                  and Mobile Application ("App"), strictly for internal business
                  operations within the United States.
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      The User may <span className="font-bold">not</span>{" "}
                      sublicense, assign, transfer, or share access to the
                      Services beyond their permitted business use.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      Access or use of the Services outside the United States is
                      strictly <span className="font-bold">prohibited</span>.
                    </span>
                  </li>
                </ul>

                <h3 className="text-lg font-bold mb-4">
                  3.2. License Restrictions
                </h3>
                <p className="text-gray-700 mb-4">
                  The User shall <span className="font-bold">not</span>:
                </p>
                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      Copy, modify, decompile, reverse-engineer, or attempt to
                      extract the source code of the Software or App.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      Sell, sublicense, rent, assign, transfer, or share access
                      to the Services with any third party.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Use the Services for fraudulent, deceptive, or unlawful
                      activity.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <span>
                      Interfere with, disrupt, or attempt to gain unauthorized
                      access to the Services or related systems.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(e)</span>
                    <span>
                      Deploy automated scripts, bots, or scraping tools to
                      extract data without Emerald Data Solutions' prior written
                      consent.
                    </span>
                  </li>
                </ul>

                <h3 className="text-lg font-bold mb-4">
                  3.3. Mobile Application-Specific Terms
                </h3>
                <p className="text-gray-700 mb-4">
                  In addition to the above restrictions, the following terms
                  apply specifically to the App:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      <span className="font-semibold">Installation & Use:</span>{" "}
                      The User may download, install, and use the App{" "}
                      <span className="font-bold">solely</span> for internal
                      business purposes.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      <span className="font-semibold">
                        Platform Compliance:
                      </span>{" "}
                      The User must comply with all applicable policies of
                      distribution platforms (e.g., Apple App Store, Google
                      Play). Emerald Data Solutions reserves the right to modify
                      or disable features to maintain compliance with these
                      platform rules.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <div>
                      <span className="font-semibold">Security & Updates:</span>{" "}
                      Emerald Data Solutions may release updates, modifications,
                      or security patches to maintain compliance and
                      performance. Users may be required to install these
                      updates to continue using the App.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <div>
                      <span className="font-semibold">
                        No Circumvention of Security Features:
                      </span>{" "}
                      The User shall not{" "}
                      <span className="font-bold">
                        circumvent or tamper with security features
                      </span>{" "}
                      or access controls implemented by Emerald Data Solutions.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(e)</span>
                    <div>
                      <span className="font-semibold">Termination:</span>{" "}
                      Emerald Data Solutions reserves the right to{" "}
                      <span className="font-bold">terminate access</span> to the
                      App immediately if the User violates any provisions
                      outlined in these Terms and Conditions.
                    </div>
                  </li>
                </ul>

                <h3 className="text-lg font-bold mb-4">
                  3.4. Ownership and Intellectual Property
                </h3>
                <p className="text-gray-700">
                  All rights, title, and interest in the Services, including the
                  Software, App, and related content, remain the{" "}
                  <span className="font-bold">
                    exclusive property of Emerald Data Solutions
                  </span>
                  . The User receives{" "}
                  <span className="font-bold">no ownership rights</span> beyond
                  the limited license granted herein.
                </p>
              </section>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">
                  4. PROHIBITED INDUSTRIES AND COMPLIANCE
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  4.1. Prohibited Industries
                </h3>
                <p className="text-gray-700 mb-4">
                  Users may not use the Software, App, or related Services in
                  connection with industries or activities that pose legal,
                  ethical, or reputational risks or violate U.S. laws.
                  Prohibited industries and activities include but are not
                  limited to:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      <span className="font-semibold">Adult Content</span> –
                      Businesses engaged in the production, distribution,
                      promotion, or facilitation of adult or pornographic
                      content.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      <span className="font-semibold">Gambling & Betting</span>{" "}
                      – Online or offline gambling, betting, fantasy sports,
                      lotteries, or gaming operations requiring a gambling
                      license.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <div>
                      <span className="font-semibold">Firearms & Weapons</span>{" "}
                      – The sale, manufacture, distribution, or facilitation of
                      firearms, ammunition, explosives, or weapon-related
                      accessories.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <div>
                      <span className="font-semibold">
                        Controlled Substances & Drug-Related Activities
                      </span>{" "}
                      – The sale, distribution, or manufacture of controlled
                      substances, including cannabis and its derivatives, where
                      prohibited under U.S. federal law.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(e)</span>
                    <div>
                      <span className="font-semibold">
                        High-Risk Financial Services
                      </span>{" "}
                      – Money services businesses (MSBs), unlicensed financial
                      institutions, cryptocurrency exchanges, payday lending, or
                      high-risk investment schemes.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(f)</span>
                    <div>
                      <span className="font-semibold">
                        Hate Speech & Extremist Activities
                      </span>{" "}
                      – Organizations or individuals promoting hate speech,
                      extremist ideologies, or unlawful discrimination.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(g)</span>
                    <div>
                      <span className="font-semibold">
                        Illegal or Fraudulent Activities
                      </span>{" "}
                      – Activities such as fraud, money laundering, human
                      trafficking, or any action violating U.S. federal, state,
                      or local laws.
                    </div>
                  </li>
                </ul>

                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions reserves the right to modify or expand
                  this list based on evolving legal and industry standards.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  4.2. Enforcement & Compliance
                </h3>
                <p className="text-gray-700 mb-4">
                  To ensure compliance, Emerald Data Solutions may take any of
                  the following actions:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      <span className="font-semibold">
                        Request Additional Information
                      </span>{" "}
                      – Users may be required to provide documentation verifying
                      their industry and compliance status.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      <span className="font-semibold">
                        Conduct Periodic Audits
                      </span>{" "}
                      – Accounts may be reviewed based on risk factors, customer
                      complaints, or suspicious activity.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <div>
                      <span className="font-semibold">
                        Immediate Suspension or Termination
                      </span>{" "}
                      – Accounts found in violation may be suspended or
                      terminated without prior notice.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <div>
                      <span className="font-semibold">
                        Reporting & Legal Action
                      </span>{" "}
                      – Violations may be reported to relevant authorities, and
                      Emerald Data Solutions reserves the right to take legal
                      action.
                    </div>
                  </li>
                </ul>

                <p className="text-gray-700 mb-8">
                  By using the Software or App, the User acknowledges and agrees
                  that Emerald Data Solutions retains full discretion to
                  determine whether an industry or activity falls within a
                  prohibited category.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  5. SUBSCRIPTION, PAYMENTS, AND TERMINATION
                </h2>
                <h3 className="text-lg font-bold mb-4">
                  5.1. Subscription Plans and Automatic Renewal
                </h3>

                <h4 className="text-base font-bold mb-3">
                  5.1.1. Subscription Term
                </h4>
                <p className="text-gray-700 mb-6">
                  Access to the Services is provided on a subscription basis,
                  with billing cycles determined by the User's selected plan.
                </p>

                <h4 className="text-base font-bold mb-3">
                  5.1.2. Automatic Renewal
                </h4>
                <p className="text-gray-700 mb-4">
                  All subscription plans automatically renew at the end of the
                  applicable billing cycle{" "}
                  <span className="font-bold underline">
                    unless canceled by the User withing the applicable
                    cancellation period
                  </span>
                  :
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      <span className="font-semibold">
                        MONTHLY SUBSCRIPTIONS:
                      </span>{" "}
                      Users may cancel at any time before the end of the
                      applicable monthly billing cycle date to avoid charges for
                      the next monthly billing cycle.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      <span className="font-semibold">
                        ANNUAL SUBSCRIPTIONS:
                      </span>{" "}
                      Users must cancel at least seven (7) days before the end
                      of the applicable annual billing cycle date to avoid
                      charges for the next annual billing cycle.
                    </div>
                  </li>
                </ul>

                <h4 className="text-base font-bold mb-3">
                  5.1.3. Advance Renewal Notice
                </h4>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions shall provide Users with at least
                  fifteen (15) days' advance notice via email before processing
                  an automatic renewal, unless a longer notice period is
                  required by law. This notice will include the renewal date,
                  applicable fees, and clear instructions on how to cancel the
                  subscription in accordance with Section 5.1.2 to avoid
                  automatic renewal.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  5.2. Fees, Payments, and Pricing Adjustments
                </h3>

                <h4 className="text-base font-bold mb-3">
                  5.2.1. Non-Refundable Payments
                </h4>
                <p className="text-gray-700 mb-6">
                  All fees for the Services are non-refundable, including cases
                  where the User discontinues use before the end of a billing
                  period, except as expressly provided in Section 5.3.3.
                </p>

                <h4 className="text-base font-bold mb-3">
                  5.2.2. Payment Processing
                </h4>
                <p className="text-gray-700 mb-6">
                  Users must ensure that payment information remains up to date.
                  Failure to process payment may result in immediate suspension
                  or termination of access to the Services.
                </p>

                <h4 className="text-base font-bold mb-3">
                  5.2.3. Pricing Adjustments
                </h4>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions reserves the right to adjust
                  subscription fees and pricing for any plan. Users shall
                  receive at least thirty (30) days' advance notice of any
                  changes to their selected plan.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  5.3. Cancellation and Termination
                </h3>

                <h4 className="text-base font-bold mb-3">
                  5.3.1. User Cancellation
                </h4>
                <p className="text-gray-700 mb-6">
                  Users may cancel their subscription through their account
                  settings by selecting the cancellation option in the billing
                  settings. Alternatively, Users may request cancellation by
                  contacting Emerald Data Solutions' customer support at{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  .
                </p>

                <p className="text-gray-700 mb-4">
                  Cancellations must be submitted within the applicable
                  timeframe to avoid being charged for renewal:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-6">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      <span className="font-semibold">
                        MONTHLY SUBSCRIPTIONS:
                      </span>{" "}
                      Users may cancel at any time before the end of the
                      applicable monthly billing cycle date to avoid charges for
                      the next monthly billing cycle.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      <span className="font-semibold">
                        ANNUAL SUBSCRIPTIONS:
                      </span>{" "}
                      Users must cancel at least seven (7) days before the end
                      of the applicable annual billing cycle date to avoid
                      charges for the next annual billing cycle.
                    </div>
                  </li>
                </ul>

                <p className="text-gray-700 mb-8">
                  Cancellations take effect at the end of the applicable billing
                  period, and Users will retain access to the Services until the
                  subscription term expires. No prorated refunds will be issued
                  for unused time, except as set forth in these Terms and
                  Conditions.
                </p>

                <h4 className="text-base font-bold mb-3">
                  5.3.2. Emerald Data Solutions Termination Rights
                </h4>
                <p className="text-gray-700 mb-4">
                  Emerald Data Solutions may terminate a User's subscription
                  immediately if:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>The User fails to make payment;</span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      The User violates these Terms and Conditions or engages in
                      fraudulent, illegal, or unauthorized use of the Services;
                      or
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Regulatory changes or other legal obligations prevent
                      Emerald Data Solutions from continuing to provide the
                      Services.
                    </span>
                  </li>
                </ul>

                <h4 className="text-base font-bold mb-3">
                  5.3.3. Refund Policy
                </h4>
                <p className="text-gray-700 mb-4">
                  Subscription fees are generally non-refundable, except as
                  required by applicable law. However, Emerald Data Solutions
                  may, at its sole discretion, provide a pro-rata refund of
                  unused subscription fees under the following circumstances:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-6">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      A <span className="font-bold">service failure</span>{" "}
                      prevents Users from accessing core functionalities of the
                      Services for an extended period (e.g., more than seven (7)
                      consecutive days), and the failure is due to factors
                      within Emerald Data Solutions' control.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      A <span className="font-bold">regulatory change</span> or
                      other legal obligation forces Emerald Data Solutions to
                      discontinue the Services for a specific User or region.
                    </div>
                  </li>
                </ul>

                <p className="text-gray-700 mb-8">
                  Refund requests must be submitted in writing to{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>{" "}
                  within thirty (30) days of the qualifying event.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  5.4. Post-Termination Data Access
                </h3>

                <h4 className="text-base font-bold mb-3">
                  5.4.1. Data Export Period
                </h4>
                <p className="text-gray-700 mb-6">
                  Upon termination of a User's subscription, Users shall have
                  sixty (60) days to export or retrieve their data.
                </p>

                <h4 className="text-base font-bold mb-3">
                  5.4.2. Extended Data Migration for Enterprise Users
                </h4>
                <p className="text-gray-700 mb-8">
                  Enterprise Users may request an extended data migration period
                  of up to one hundred eighty (180) days, subject to Emerald
                  Data Solutions' approval.
                </p>
              </section>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">
                  6. DATA OWNERSHIP, PRIVACY, AND SECURITY
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  6.1. User Data Ownership
                </h3>
                <p className="text-gray-700 mb-4">
                  Users retain full ownership of the data they input into the
                  Services. By using the Services, Users grant Emerald Data
                  Solutions a limited, non-exclusive, worldwide license to
                  store, process, and use such data solely to provide, maintain,
                  and improve the Services.
                </p>

                <p className="text-gray-700 mb-4">
                  Emerald Data Solutions will not access, use, or share User
                  data beyond what is necessary for the operation, maintenance,
                  and improvement of the Services without explicit User consent,
                  except as required by law.
                </p>

                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions does not sell, rent, or share User data
                  for advertising or marketing purposes. However, Emerald Data
                  Solutions may use aggregated and de-identified data for
                  analytics, software improvements, security enhancements, and
                  operational insights, provided that such data does not include
                  personally identifiable information.
                </p>

                <p className="text-gray-700 mb-8">
                  For further details on data processing practices, please refer
                  to the Privacy Policy at{" "}
                  <a
                    href="/privacy-policy"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>

                <h3 className="text-lg font-bold mb-4">6.2. Data Security</h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions implements industry-standard security
                  measures, including encryption protocols provided by Amazon
                  Web Services (AWS), to protect User data from unauthorized
                  access, disclosure, or loss. While Emerald Data Solutions
                  follows strict security practices, no system is entirely
                  immune to security risks.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  6.3. Data Retention and Deletion
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions retains User data for up to three (3)
                  years following account termination, unless a different
                  retention period is required by law or contractual obligation.
                  Users may request data deletion by submitting a request to{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  . However, Emerald Data Solutions may deny such requests if
                  data retention is necessary for legal compliance, agreement
                  enforcement, fraud prevention, or legitimate business needs.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  7. DATA PROTECTION, PRIVACY, AND COMPLIANCE
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  7.1. Compliance with U.S. Data Protection Laws
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions complies with applicable U.S. data
                  protection laws, including the California Consumer Privacy Act
                  (CCPA) and other relevant federal and state regulations
                  governing data privacy and security.
                </p>

                <h3 className="text-lg font-bold mb-4">7.2. User Rights</h3>
                <p className="text-gray-700 mb-4">Users have the right to:</p>
                <ul className="list-none space-y-4 pl-4 mb-6">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      Access the personal data collected by Emerald Data
                      Solutions.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      Obtain a copy of their data in a portable format.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Request correction or deletion of inaccurate or outdated
                      personal information.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <span>
                      Restrict processing of their personal information under
                      applicable U.S. laws.
                    </span>
                  </li>
                </ul>

                <p className="text-gray-700 mb-8">
                  Users may submit requests to{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  . Emerald Data Solutions will process requests in accordance
                  with applicable legal timeframes. Requests for data deletion
                  may be denied if retention is necessary for legal compliance,
                  agreement enforcement, fraud prevention, or business
                  operations.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  7.3. Third-Party Data Sharing
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions does not sell or rent User data.
                  However, User data may be shared with third-party service
                  providers that support payment processing, cloud storage,
                  analytics, and customer support. These providers must maintain
                  data confidentiality and may only use User data to fulfill
                  their service obligations. Users will be notified of any
                  material changes in third-party data-sharing practices.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  8. DATA BREACH NOTIFICATION
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  8.1. Notification of Breach
                </h3>
                <p className="text-gray-700 mb-4">
                  If a confirmed data breach involves User data:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      Emerald Data Solutions will notify affected Users within
                      seventy-two (72) hours of determining that a breach has
                      occurred.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      Notifications will be sent via email to the registered
                      account holder and will include details on the breach,
                      potential impacts, and recommended actions.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Emerald Data Solutions will cooperate with regulatory
                      authorities as required by law and provide timely updates
                      as new information becomes available.
                    </span>
                  </li>
                </ul>

                <h2 className="text-xl font-bold mb-4">
                  9. DATA COLLECTION AND USAGE
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  9.1. Aggregated and Anonymized Data
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions may collect and use aggregated and
                  anonymized data to analyze performance, identify trends, and
                  optimize Services. This data does not contain personally
                  identifiable information and cannot be aed to individual
                  Users.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  9.2. AI-Generated Data Usage and Analytics
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions does not access, process, or use User
                  data for AI model training or development without express User
                  consent. However, anonymized and aggregated system interaction
                  data may be used to improve software functionality, enhance
                  service offerings, and develop predictive maintenance models.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  9.3. Transparency and User Control
                </h3>
                <p className="text-gray-700 mb-8">
                  Users may request a summary of their data usage and analytics
                  by contacting{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  . Emerald Data Solutions is committed to transparency and
                  responsible data handling, ensuring Users retain control over
                  data usage preferences.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  10. AI AND USER-GENERATED DATA
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  10.1. Use of User Data for AI Training
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions does not use customer data for training
                  artificial intelligence models unless expressly permitted by
                  the User. Users may opt in to contribute anonymized or
                  aggregated data for improving software performance,
                  automation, and predictive analytics.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  10.2. Opt-Out Mechanism
                </h3>
                <p className="text-gray-700 mb-8">
                  Users may opt out of AI data usage at any time through their
                  account settings or by submitting a request to{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  .
                </p>

                <h3 className="text-lg font-bold mb-4">
                  10.3. Data Protection and Security
                </h3>
                <p className="text-gray-700 mb-8">
                  All customer data remains subject to Emerald Data Solutions'
                  Privacy Policy, ensuring encryption, access control, and
                  compliance with industry-standard security measures.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  11. PLATFORM COMPLIANCE AND DATA COLLECTION POLICIES
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  11.1. Compliance with App Store and Platform Policies
                </h3>
                <p className="text-gray-700 mb-8">
                  Users must comply with all applicable policies of distribution
                  platforms, including Apple's App Store and Google Play. This
                  includes adhering to content restrictions, prohibited conduct,
                  and privacy regulations. To maintain compliance, Emerald Data
                  Solutions may modify or disable features at any time.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  11.2. Tracking, Analytics, and User Privacy
                </h3>
                <p className="text-gray-700 mb-4">
                  Emerald Data Solutions values User privacy and adheres to
                  applicable U.S. data protection regulations. By using the
                  Services, Users acknowledge and consent to analytics and
                  tracking data collection as outlined below:
                </p>

                <h4 className="text-base font-bold mb-3">
                  11.2.1. User Consent and Data Collection
                </h4>
                <p className="text-gray-700 mb-6">
                  Users may opt out of tracking via Apple's App Tracking
                  Transparency (ATT) framework or Google's privacy settings.
                  Tracking is enabled only with explicit consent.
                </p>

                <h4 className="text-base font-bold mb-3">
                  11.2.2. App Tracking Transparency and Privacy Controls
                </h4>
                <p className="text-gray-700 mb-4">
                  On Apple devices, tracking follows Apple's ATT framework,
                  requiring explicit permission.
                </p>
                <p className="text-gray-700 mb-8">
                  On Android devices, tracking preferences adhere to Google's
                  privacy settings, allowing Users to opt out of personalized
                  advertising or tracking.
                </p>

                <h4 className="text-base font-bold mb-3">
                  11.2.3. Geolocation Data
                </h4>
                <p className="text-gray-700 mb-8">
                  The Service does not collect or store precise geolocation data
                  without express consent. Users will receive clear notice
                  before collection and may revoke consent at any time through
                  App settings.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  12. THIRD-PARTY INTEGRATIONS AND FAIR USAGE
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  12.1. Third-Party Services and Integrations
                </h3>
                <p className="text-gray-700 mb-8">
                  The Service integrates with third-party platforms such as
                  Stripe, QuickBooks, and Google Analytics to enhance
                  functionality. These integrations are provided "as-is," and
                  Emerald Data Solutions makes no guarantees regarding
                  availability, compatibility, or uninterrupted operation. Users
                  acknowledge that Third-Party Services operate independently,
                  and Emerald Data Solutions is not responsible for their
                  security, reliability, or performance.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  12.2. Modifications and Discontinuation
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions may modify, suspend, or discontinue
                  third-party integrations at its discretion without liability.
                  Emerald Data Solutions explicitly disclaims responsibility for
                  issues arising from Third-Party Services, including
                  unauthorized access, data breaches, or service disruptions.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  12.3. User Responsibilities and Indemnification
                </h3>
                <p className="text-gray-700 mb-8">
                  Users utilizing third-party integrations do so at their own
                  risk and agree to indemnify, defend, and hold harmless Emerald
                  Data Solutions from any claims, damages, or liabilities
                  arising from their use, including security vulnerabilities or
                  unauthorized access.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  12.4. System Resource Use and Fair Usage Policy
                </h3>

                <h4 className="text-base font-bold mb-3">
                  12.4.1. System Resource Allocation
                </h4>
                <p className="text-gray-700 mb-6">
                  To ensure stable performance and fair access, Emerald Data
                  Solutions may impose reasonable limits on system resource
                  usage. Excessive resource consumption may result in
                  restrictions.
                </p>

                <h4 className="text-base font-bold mb-3">
                  12.4.2. Automated Interactions
                </h4>
                <p className="text-gray-700 mb-8">
                  Automated or scripted interactions must comply with fair usage
                  guidelines. Emerald Data Solutions may restrict or throttle
                  access if system requests are excessive or abusive.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  13. APP-SPECIFIC PERMISSIONS AND USER CONTROLS
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  13.1. Push Notifications
                </h3>
                <p className="text-gray-700 mb-8">
                  The App may send notifications for maintenance alerts, system
                  updates, and workflow approvals. Users can manage notification
                  preferences in App settings.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  13.2. Camera and Microphone Access
                </h3>
                <p className="text-gray-700 mb-8">
                  The App may request camera and microphone access for barcode
                  scanning, photo attachments, or voice input. Users grant these
                  permissions at their discretion and can modify them in device
                  settings.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  13.3. Local Data Storage
                </h3>
                <p className="text-gray-700 mb-8">
                  To enhance offline functionality, the App may store certain
                  data locally. Users may clear stored data via App settings.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  14. ADVERTISING AND MONETIZATION
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  14.1. No Sale of User Data
                </h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions does not sell, rent, or share User data
                  for advertising or monetization.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  14.2. Ad-Free Experience
                </h3>
                <p className="text-gray-700 mb-8">
                  The Software and App do not contain third-party
                  advertisements. Any promotional content is limited to product
                  enhancements, feature updates, or internal Emerald Data
                  Solutions communications.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  14.3. Limitations on Ad Tracking
                </h3>
                <p className="text-gray-700 mb-8">
                  The App does not engage in targeted advertising. Data
                  collection is solely for service improvement, operational
                  analytics, and User experience optimization.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  15. LIMITATION OF LIABILITY AND AI/ANALYTICS DISCLAIMER
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  15.1. Limitation of Liability
                </h3>
                <p className="text-gray-700 mb-8">
                  To the maximum extent permitted by law, Emerald Data Solutions
                  shall not be liable for any indirect, incidental,
                  consequential, special, exemplary, or punitive damages,
                  including but not limited to data loss, lost profits, business
                  interruptions, reputational harm, or opportunity costs, even
                  if advised of the possibility of such damages.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  15.2. Maximum Liability
                </h3>
                <p className="text-gray-700 mb-8">
                  In no event shall Emerald Data Solutions' total liability
                  exceed the amount paid by the User for the Services in the
                  twelve (12) months preceding the claim.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  15.3. AI and Analytics Disclaimer
                </h3>
                <p className="text-gray-700 mb-8">
                  The Services may generate insights, reports, or
                  recommendations using artificial intelligence (AI) and
                  analytics tools. AI-generated outputs are for informational
                  purposes only and do not constitute professional, legal,
                  financial, or operational advice. Users are solely responsible
                  for verifying and assessing AI-generated recommendations
                  before making decisions. Emerald Data Solutions does not
                  guarantee the accuracy, reliability, or applicability of
                  AI-generated data.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  15.4. Third-Party Platform Disclaimer
                </h3>
                <p className="text-gray-700 mb-8">
                  Users acknowledge that third-party platforms such as Apple
                  Inc. and Google LLC bear no responsibility for the App, its
                  content, security, or compliance with these Terms and
                  Conditions.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  16. USER RESPONSIBILITY FOR DEVICE SECURITY
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  16.1. Device Security
                </h3>
                <p className="text-gray-700 mb-8">
                  Users must maintain the security of devices used to access the
                  Service.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  16.2. Device Modifications
                </h3>
                <p className="text-gray-700 mb-8">
                  The Service must not be used on rooted or jailbroken devices,
                  as such modifications compromise security.
                </p>

                <h3 className="text-lg font-bold mb-4">16.3. System Updates</h3>
                <p className="text-gray-700 mb-8">
                  Users must keep their device operating systems and security
                  software updated to minimize security risks.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  17. ARBITRATION AND DISPUTE RESOLUTION
                </h2>

                <h3 className="text-lg font-bold mb-4">17.1. Governing Law</h3>
                <p className="text-gray-700 mb-8">
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of the State of Ohio, without
                  regard to its conflict of laws principles.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  17.2. Informal Resolution
                </h3>
                <p className="text-gray-700 mb-8">
                  To expedite resolution and control the cost of any dispute,
                  the Parties agree to first attempt to negotiate any dispute
                  (except those expressly provided below) informally for at
                  least <span className="font-bold">thirty (30) days</span>{" "}
                  before initiating arbitration. Such informal negotiations
                  shall commence upon written notice from one Party to the
                  other.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  17.3. BINDING ARBITRATION
                </h3>
                <div className="bg-gray-50 p-4 border-l-4 border-gray-400 mb-8">
                  <p className="text-gray-700 font-bold mb-4">
                    ANY DISPUTE, CLAIM, OR CONTROVERSY ARISING FROM OR RELATING
                    TO THESE TERMS OR THE USE OF THE SERVICES SHALL BE RESOLVED
                    EXCLUSIVELY THROUGH BINDING ARBITRATION, ADMINISTERED BY THE
                    AMERICAN ARBITRATION ASSOCIATION ("AAA") UNDER ITS
                    APPLICABLE RULES. BY AGREEING TO THESE TERMS, YOU EXPRESSLY
                    WAIVE YOUR RIGHT TO LITIGATE DISPUTES IN COURT OR TO HAVE A
                    JURY TRIAL.
                  </p>

                  <p className="text-gray-700">
                    Arbitration shall take place in Ohio unless the Parties
                    mutually agree otherwise. The arbitration may be conducted
                    in person, through the submission of documents, by phone, or
                    online. The arbitrator must follow applicable law, and any
                    arbitration award may be challenged if the arbitrator fails
                    to do so. The arbitrator shall provide a written decision,
                    and any award may be entered in a court of competent
                    jurisdiction.
                  </p>
                </div>

                <h3 className="text-lg font-bold mb-4">
                  17.4. SMALL CLAIMS COURT EXCEPTION
                </h3>
                <div className="bg-gray-50 p-4 border-l-4 border-gray-400 mb-8">
                  <p className="text-gray-700 font-bold">
                    NOTWITHSTANDING THE FOREGOING, EITHER PARTY MAY BRING AN
                    INDIVIDUAL CLAIM IN SMALL CLAIMS COURT IN THE STATE IN WHICH
                    THE USER RESIDES, PROVIDED THE CLAIM QUALIFIES UNDER THE
                    APPLICABLE JURISDICTIONAL REQUIREMENTS.
                  </p>
                </div>

                <h3 className="text-lg font-bold mb-4">
                  17.5. Exceptions to Arbitration
                </h3>
                <p className="text-gray-700 mb-8">
                  The following disputes are not subject to arbitration:
                  <br />
                  (a) Individual claims filed in small claims court in the
                  User's state of residence, provided the claim meets
                  jurisdictional requirements;
                  <br />
                  (b) Disputes concerning the enforcement or validity of either
                  Party's intellectual property rights;
                  <br />
                  (c) Claims related to theft, piracy, invasion of privacy, or
                  unauthorized use; and
                  <br />
                  (d) Claims seeking injunctive or equitable relief.
                </p>

                <p className="text-gray-700 mb-8">
                  If arbitration is deemed unenforceable or inapplicable,
                  disputes shall be litigated exclusively in the state or
                  federal courts of Ohio, and Users waive their right to a jury
                  trial.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  17.6. WAIVER OF CLASS ACTIONS AND JURY TRIAL
                </h3>
                <div className="bg-gray-50 p-4 border-l-4 border-gray-400 mb-8">
                  <p className="text-gray-700 font-bold mb-4">
                    TO THE FULLEST EXTENT PERMITTED BY LAW, ALL DISPUTES SHALL
                    BE ARBITRATED OR LITIGATED ONLY ON AN INDIVIDUAL BASIS.
                    USERS EXPRESSLY WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS
                    ACTION OR CLASS-WIDE ARBITRATION OR TO BRING CLAIMS IN A
                    REPRESENTATIVE CAPACITY ON BEHALF OF OTHERS.
                  </p>

                  <p className="text-gray-700 font-bold">
                    IN THE EVENT THAT ARBITRATION IS DEEMED UNENFORCEABLE OR
                    INAPPLICABLE, ANY LEGAL PROCEEDINGS SHALL BE CONDUCTED
                    EXCLUSIVELY IN THE STATE OR FEDERAL COURTS OF OHIO. IN SUCH
                    INSTANCES, USERS EXPRESSLY WAIVE THEIR RIGHT TO A JURY
                    TRIAL.
                  </p>
                </div>

                <h3 className="text-lg font-bold mb-4">17.7. Severability</h3>
                <p className="text-gray-700 mb-8">
                  If any portion of this Section 17 is found to be illegal or
                  unenforceable, the remainder shall remain in full force and
                  effect. Any disputes that cannot be arbitrated shall be
                  resolved exclusively in the state or federal courts of Ohio,
                  and the Parties consent to personal jurisdiction in such
                  courts.
                </p>

                <h2 className="text-xl font-bold mb-4">18. INDEMNIFICATION</h2>
                <p className="text-gray-700 mb-4">
                  Users shall indemnify, defend, and hold harmless Emerald Data
                  Solutions, its affiliates, officers, employees, and agents
                  against any claims, liabilities, damages, losses, costs, and
                  expenses (including reasonable attorneys' fees) arising from
                  or related to:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      Misuse of the Services, including unauthorized access,
                      data breaches, or security vulnerabilities resulting from
                      the User's negligence;
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>Violation of these Terms;</span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Infringement, misappropriation, or violation of
                      third-party rights, including intellectual property,
                      privacy, or proprietary rights;
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <span>
                      Third-party claims related to content uploaded,
                      transmitted, or provided by the User, including copyright
                      violations, defamation, or unauthorized disclosures; and
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(e)</span>
                    <span>
                      Failure to maintain adequate security measures, resulting
                      in unauthorized access, data loss, or compromise of
                      Emerald Data Solutions' systems or other Users'
                      information.
                    </span>
                  </li>
                </ul>
              </section>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">
                  19. USER SUPPORT AND ESCALATION PROCEDURES
                </h2>

                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions provides customer support through
                  designated channels during business hours. Support includes
                  troubleshooting, User guidance, and feature-related inquiries.
                  Support does not cover third-party integrations or
                  User-specific customizations.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  19.1. Standard Support
                </h3>
                <p className="text-gray-700 mb-8">
                  Standard support is available Monday through Friday, 9 AM–5 PM
                  EST. Users receive assistance with general troubleshooting,
                  software functionality, and usage questions.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  19.2. Priority Support
                </h3>
                <p className="text-gray-700 mb-8">
                  Users subscribed to a premium support plan receive priority
                  support, including expedited response times and dedicated
                  support representatives.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  19.3. Emergency Support
                </h3>
                <p className="text-gray-700 mb-8">
                  Emergency support is available for critical system failures
                  that significantly impact business operations. Support
                  requests can be submitted via 24/7, and automated responses
                  will confirm receipt of these requests. A qualified support
                  representative will respond to emergency issues within four
                  (4) business hours during standard support hours, which are
                  Monday through Friday, 8 AM to 7 PM (ET). For issues reported
                  outside of standard support hours, response times may vary,
                  but we will prioritize emergency issues as soon as support
                  staff are available.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  19.4. Escalation Procedures
                </h3>
                <p className="text-gray-700 mb-4">
                  Unresolved issues escalate through the following tiers:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      <span className="font-semibold">Tier 1 Support</span> –
                      Basic troubleshooting
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      <span className="font-semibold">Tier 2 Support</span> –
                      Advanced technical assistance
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      <span className="font-semibold">Tier 3 Support</span> –
                      Senior engineers and system architects
                    </span>
                  </li>
                </ul>

                <h2 className="text-xl font-bold mb-4">
                  20. EMERGENCY SUSPENSION AND TERMINATION
                </h2>

                <p className="text-gray-700 mb-4">
                  Emerald Data Solutions may immediately suspend or terminate a
                  User's access to the Service under the following
                  circumstances:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <div>
                      <span className="font-semibold">Security Risks</span> – If
                      a security vulnerability is detected that threatens Users,
                      the Service, or Emerald Data Solutions' infrastructure.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <div>
                      <span className="font-semibold">Legal Compliance</span> –
                      If required by law enforcement, regulatory authorities, or
                      a court order.
                    </div>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <div>
                      <span className="font-semibold">Policy Violations</span> –
                      If the Service is found to be in violation of platform
                      policies, including but not limited to Apple App Store and
                      Google Play requirements.
                    </div>
                  </li>
                </ul>

                <p className="text-gray-700 mb-8">
                  Users will receive notice of the suspension, including the
                  reason and any available remedial steps, when applicable.
                </p>
              </section>

              <section className="mt-8">
                <h2 className="text-xl font-bold mb-4">
                  21. SERVICE AVAILABILITY, UPTIME, AND FORCE MAJEURE
                </h2>

                <h3 className="text-lg font-bold mb-4">21.1. Service Uptime</h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions aims to maintain a minimum of 99%
                  uptime but does not guarantee uninterrupted availability,
                  performance, or error-free operation.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  21.2. Service Warranty Disclaimer
                </h3>
                <p className="text-gray-700 mb-8">
                  The Services are provided on an "as is" and "as available"
                  basis without express or implied warranties regarding
                  reliability, performance, or functionality.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  21.3. Maintenance and Updates
                </h3>
                <p className="text-gray-700 mb-8">
                  Scheduled maintenance, system updates, or unforeseen technical
                  issues may cause temporary service interruptions. Emerald Data
                  Solutions shall make reasonable efforts to notify Users in
                  advance of planned downtime.
                </p>

                <h3 className="text-lg font-bold mb-4">21.4. Force Majeure</h3>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions shall not be liable for service
                  disruptions caused by external factors beyond its control,
                  including but not limited to natural disasters, cyber-attacks,
                  labor strikes, third-party service failures, governmental
                  actions, regulatory changes, or telecommunications and
                  internet disruptions. Emerald Data Solutions shall use
                  commercially reasonable efforts to restore normal operations
                  as soon as practicable.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  22. DATA ACCESS AND RETENTION FOLLOWING TERMINATION
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  22.1. Data Export Period
                </h3>
                <p className="text-gray-700 mb-8">
                  Users have sixty (60) days after account termination to export
                  their data before permanent deletion.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  22.2. Extended Migration Period
                </h3>
                <p className="text-gray-700 mb-8">
                  Enterprise Users may request an extended migration period of
                  up to one hundred eighty (180) days, subject to Emerald Data
                  Solutions' approval.
                </p>

                <h3 className="text-lg font-bold mb-4">22.3. Data Deletion</h3>
                <p className="text-gray-700 mb-8">
                  Once the applicable period expires, all User data will be
                  permanently deleted and cannot be recovered.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  23. MISCELLANEOUS PROVISIONS
                </h2>

                <h3 className="text-lg font-bold mb-4">
                  23.1. Auto-Scaling & Performance Adjustments
                </h3>

                <h4 className="text-base font-bold mb-3">
                  23.1.1. System Scalability
                </h4>
                <p className="text-gray-700 mb-6">
                  Emerald Data Solutions implements auto-scaling technologies to
                  dynamically allocate resources, ensuring optimal system
                  performance and service continuity during peak usage.
                </p>

                <h4 className="text-base font-bold mb-3">
                  23.1.2. Load Balancing & Caching
                </h4>
                <p className="text-gray-700 mb-6">
                  The software infrastructure incorporates caching mechanisms
                  and traffic balancing strategies to maintain response times
                  and prevent service disruptions.
                </p>

                <h4 className="text-base font-bold mb-3">
                  23.1.3. Performance Monitoring
                </h4>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions continuously monitors system
                  performance and reserves the right to adjust resource
                  allocation as needed to enhance service availability and
                  mitigate potential bottlenecks.
                </p>

                <h3 className="text-lg font-bold mb-4">
                  23.2. Export Control & Compliance
                </h3>

                <h4 className="text-base font-bold mb-3">
                  23.2.1. U.S. Export Compliance
                </h4>
                <p className="text-gray-700 mb-4">
                  Users acknowledge and agree that:
                </p>

                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      The Services and underlying software are subject to U.S.
                      export control laws and regulations.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      The Services may not be used, exported, re-exported, or
                      transferred to:
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Any country subject to U.S. trade sanctions or embargoes
                      (e.g., Cuba, Iran, North Korea, Syria).
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(d)</span>
                    <span>
                      Any individual or entity listed on the U.S. Treasury
                      Department's Specially Designated Nationals (SDN) List or
                      any similar prohibited persons list.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(e)</span>
                    <span>
                      The Services may not be used for military, nuclear,
                      biochemical, or other restricted activities that violate
                      U.S. export laws.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(f)</span>
                    <span>
                      Users represent and warrant that they are not located in,
                      under the control of, or a national or resident of any
                      restricted country or listed entity. Users must comply
                      with all applicable export and trade regulations when
                      accessing or distributing the Services.
                    </span>
                  </li>
                </ul>

                <h3 className="text-lg font-bold mb-4">
                  23.3. Amendments & Compliance with Future Regulations
                </h3>

                <h4 className="text-base font-bold mb-3">
                  23.3.1. Amendments to Terms
                </h4>
                <p className="text-gray-700 mb-8">
                  Emerald Data Solutions may update these Terms and Conditions
                  at its sole discretion. Any revisions become effective upon
                  posting. Continued use of the Services after updates take
                  effect constitutes acceptance of the modified Terms.
                </p>

                <h4 className="text-base font-bold mb-3">
                  23.3.2. Regulatory Compliance Adjustments
                </h4>
                <ul className="list-none space-y-4 pl-4 mb-8">
                  <li className="flex gap-2">
                    <span className="font-semibold">(a)</span>
                    <span>
                      Emerald Data Solutions reserves the right to modify these
                      Terms and Conditions in response to changes in applicable
                      laws or regulations.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(b)</span>
                    <span>
                      Users will receive at least thirty (30) days' advance
                      notice before any material changes take effect, except
                      where immediate compliance is required by law.
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <span className="font-semibold">(c)</span>
                    <span>
                      Continued use of the Services after the effective date of
                      any modifications constitutes acceptance of the updated
                      Terms.
                    </span>
                  </li>
                </ul>

                <h3 className="text-lg font-bold mb-4">23.4. Non-Waiver</h3>
                <p className="text-gray-700 mb-8">
                  The failure of Emerald Data Solutions to exercise or enforce
                  any right or provision of these Terms and Conditions shall not
                  constitute a waiver of such right or provision. Any waiver of
                  any provision of these Terms and Conditions will be effective
                  only if in writing and signed by an authorized representative
                  of Emerald Data Solutions.
                </p>

                <h3 className="text-lg font-bold mb-4">23.5. Severability</h3>
                <p className="text-gray-700 mb-8">
                  If any provision of these Terms and Conditions is found to be
                  invalid, illegal, or unenforceable by a court of competent
                  jurisdiction, the remaining provisions shall remain in full
                  force and effect. Any such provision shall be modified to the
                  extent necessary to make it valid and enforceable while
                  preserving the original intent of the parties. If modification
                  is not possible, the provision shall be deemed severed, and
                  the remaining Terms shall be interpreted as if the severed
                  provision had never been included.
                </p>

                <h2 className="text-xl font-bold mb-4">
                  24. CONTACT INFORMATION
                </h2>
                <p className="text-gray-700 mb-8">
                  For any inquiries regarding these Terms and Conditions, Users
                  may contact Emerald Data Solutions at:
                </p>

                <div className="bg-gray-50 p-6 rounded-lg text-center mb-8">
                  <h3 className="text-xl font-bold mb-4">
                    Emerald Data Solutions
                  </h3>
                  <p className="text-gray-700 mb-2">
                    6715 Portland Road, Sandusky, OH, 44870
                  </p>
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline mb-2 block"
                  >
                    support@emeralddata.io
                  </a>
                  <p className="text-gray-700">724-884-3596</p>
                </div>
              </section>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
