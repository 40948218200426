import { motion } from "framer-motion";
const PrivacyPolicy = () => {
  return (
    <div className="w-full md:py-[8rem] py-12 mb-12 lg:mb-0 text-center lg:text-left container flex flex-col items-center lg:flex-row ">
      <div className="w-full">
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="w-full"
        >
          <h1 className="md:text-5xl text-xl text-center leading-7 font-semibold md:leading-9 text-gray-900  w-full font-inter">
            EMERALD DATA SOLUTIONS <br />
          </h1>
          <div className="max-w-4xl mx-auto px-4 py-8">
            {/* <h1 className="text-3xl font-bold text-center mb-8">
              EMERALD DATA SOLUTIONS
            </h1> */}
            <h2 className="text-2xl font-bold text-center mb-6">
              PRIVACY POLICY
            </h2>

            <div className="space-y-6">
              <section>
                <p className="mb-4">Effective Date: {"04/03/2025"}</p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">1. Introduction</h3>
                <p className="mb-4">
                  Welcome to Emerald Data Solutions ("we," "us," or "our"). We
                  value your privacy and are committed to protecting your
                  personal information. This Privacy Policy describes how we
                  collect, use, and share information when you use our software,
                  website, mobile application, and related services
                  (collectively, the "Services").
                </p>
                <p className="mb-4">
                  By using the Services, you agree to the collection and use of
                  information in accordance with this Privacy Policy.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  2. Information We Collect
                </h3>
                <p className="mb-3">
                  We collect information to provide, maintain, and improve our
                  Services. The types of information we collect include:
                </p>

                <h4 className="text-lg font-medium mt-4 mb-2">
                  a. Information You Provide to Us:
                </h4>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>
                    Contact information (e.g., name, email address, phone
                    number, mailing address)
                  </li>
                  <li>Account credentials (e.g., username, password)</li>
                  <li>
                    Payment and billing information (e.g., credit card details,
                    billing address)
                  </li>
                  <li>
                    User-generated content (e.g., comments, messages, feedback)
                  </li>
                  <li>
                    Business-related data (e.g., organization name, industry,
                    job title)
                  </li>
                </ul>

                <h4 className="text-lg font-medium mt-4 mb-2">
                  b. Information Collected Automatically:
                </h4>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>
                    Device information (e.g., IP address, browser type,
                    operating system)
                  </li>
                  <li>
                    Log data (e.g., access times, pages viewed, system activity)
                  </li>
                  <li>
                    Usage data (e.g., feature usage, interactions, error
                    reports)
                  </li>
                </ul>

                <h4 className="text-lg font-medium mt-4 mb-2">
                  c. Information from Third Parties:
                </h4>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>
                    Business partners providing integrations and additional
                    functionalities (e.g., Stripe, Dazzle's ProgressBook,
                    PowerSchool, QuickBooks)
                  </li>
                  <li>Public databases or third-party sources</li>
                  <li>Analytics and marketing partners</li>
                </ul>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  3. User Data Ownership & Responsibility
                </h3>
                <p className="mb-4">
                  Users retain ownership of the data they input. By submitting
                  data, users grant Emerald Data Solutions a non-exclusive,
                  royalty-free, worldwide license to use, store, and process
                  such data for the purpose of providing and improving the
                  Services. Users are responsible for ensuring the accuracy and
                  legality of their submitted data. We assume no liability for
                  data inaccuracies, omissions, or any consequences resulting
                  from user-entered information.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  4. How We Use Your Information
                </h3>
                <p className="mb-3">
                  We use the collected information for the following purposes:
                </p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>To provide and operate the Services</li>
                  <li>To process transactions and manage billing</li>
                  <li>To authenticate users and ensure security</li>
                  <li>To personalize user experience</li>
                  <li>To provide customer support and troubleshooting</li>
                  <li>
                    To analyze usage trends and improve Services functionality
                  </li>
                  <li>
                    To send administrative and promotional communications
                    (opt-out available)
                  </li>
                  <li>
                    To comply with legal obligations and protect rights and
                    safety
                  </li>
                  <li>
                    To facilitate financial reporting and asset tracking for
                    educational institutions
                  </li>
                </ul>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  5. Information Sharing and Disclosure
                </h3>
                <p className="mb-4">
                  We do not sell personal information. We may share information
                  in the following circumstances:
                </p>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-lg font-medium mb-2">
                      With Service Providers:
                    </h4>
                    <p>
                      Third-party vendors assisting with hosting, analytics,
                      payment processing, and support
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg font-medium mb-2">
                      With Business Partners:
                    </h4>
                    <p>
                      When integrating with third-party services or working with
                      authorized resellers
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg font-medium mb-2">
                      For Legal Reasons:
                    </h4>
                    <p>
                      To comply with applicable laws, regulations, legal
                      processes, or enforceable governmental requests
                    </p>
                  </div>
                  <div>
                    <h4 className="text-lg font-medium mb-2">
                      In Business Transfers:
                    </h4>
                    <p>
                      If Emerald Data Solutions undergoes a merger, financing,
                      reorganization, joint venture, acquisition, or the sale or
                      transfer of assets, we expect that any and all
                      information, including personal information, will be among
                      the assets transferred or considered to be transferred.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">6. APIs and SDKs</h3>
                <p className="mb-4">
                  Our Services may use third-party application programming
                  interfaces (APIs) and software development kits (SDKs),
                  including those provided by Google, for authentication,
                  analytics, or performance monitoring. We comply with Google's
                  API Services User Data Policy to ensure secure and responsible
                  handling of user data.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  7. Data Collection for Third-Party Tracking and Analytics
                </h3>
                <p className="mb-4">
                  Our Services may use third-party analytics providers or
                  advertising partners that collect data to improve user
                  experience, monitor engagement, or serve personalized content.
                  These third parties may collect information such as device
                  identifiers, usage patterns, and interaction data to optimize
                  app performance and deliver relevant content. Users can manage
                  their data preferences within the app or adjust their device
                  settings to limit data sharing with third parties.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  8. Anonymized Data for AI & Service Improvements
                </h3>
                <p className="mb-4">
                  We may use de-identified and aggregated data for analytics,
                  artificial intelligence improvements, and Service
                  optimization. Such data will not contain personally
                  identifiable information and will only be used to enhance
                  system functionality, security, and performance.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  9. Data Retention Following Account Termination
                </h3>
                <p className="mb-4">
                  We retain user data and information for up to three years.
                </p>
                <p className="mb-4">
                  Users who wish to delete their account and associated data may
                  do so by submitting a request to{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  . Upon request, we will delete all personal data except where
                  retention is legally required. Some information may be
                  retained for security, fraud prevention, or legal and
                  regulatory compliance purposes.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  10. Service Termination and Data Migration
                </h3>
                <p className="mb-4">
                  In the event Emerald Data Solutions discontinues its Services,
                  merges with another company, or undergoes a business
                  restructuring, users will be provided at least 60 days notice
                  before termination. Users will have the ability to export
                  their data in a structured, commonly used, and
                  machine-readable format. After the specified retention period,
                  all remaining user data will be permanently deleted.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  11. Security Measures
                </h3>
                <p className="mb-4">
                  We implement industry-standard security practices, including
                  AWS encryption services, to protect data from unauthorized
                  access, disclosure, or destruction. While we implement
                  industry-standard security measures, users are encouraged to
                  use strong passwords and regularly review account activity.
                  However, no security measure is foolproof, and we cannot and
                  do not make any guarantees as to the absolute security of your
                  information or data.
                </p>
              </section>

              <section>
                <p className="mb-4">
                  If our Services collect or process sensitive data (e.g.,
                  financial details, biometric data), we implement
                  industry-standard encryption and security protocols to protect
                  this information. We do not process biometric data for
                  authentication or tracking purposes.
                </p>
                <p className="mb-4">
                  In the event of a data breach compromising personal
                  information, we will notify affected users as required by
                  applicable laws. Users will receive notifications via email,
                  platform alerts, or public disclosure, depending on regulatory
                  requirements.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  12. User Choices and Rights
                </h3>
                <p className="mb-3">Users may:</p>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li>Access, update, or delete their personal information</li>
                  <li>Opt out of marketing communications</li>
                  <li>
                    Restrict or object to data processing in certain
                    circumstances
                  </li>
                  <li>Request a copy of their data in a portable format</li>
                </ul>
                <p className="mb-4">
                  Requests to exercise these rights can be submitted to{" "}
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    support@emeralddata.io
                  </a>
                  .
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  13. Opt-Out and User Control Over Tracking
                </h3>
                <p className="mb-4">
                  Users who wish to opt out of data collection for marketing,
                  analytics, or tracking purposes may do so by adjusting the
                  privacy settings within the app or through their device
                  settings. For mobile applications, users can manage
                  permissions related to data tracking and advertising under
                  their respective platform's privacy controls. Opting out of
                  certain data collection activities may affect the
                  functionality of some features.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  14. Cookies and Tracking Technologies
                </h3>
                <p className="mb-4">
                  We use cookies, web beacons, and other tracking technologies
                  to enhance user experience and analyze site traffic. Users can
                  manage cookie preferences through their browser settings.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  15. App Tracking Transparency Compliance
                </h3>
                <p className="mb-4">
                  If our Services use tracking technologies for advertising,
                  analytics, or user experience improvements, users will be
                  prompted to consent to such tracking in accordance with
                  applicable platform privacy frameworks. Users can manage their
                  tracking preferences through the app's privacy settings or
                  their device settings at any time.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  16. Third-Party Links and Services
                </h3>
                <p className="mb-4">
                  Our Services may include links to third-party websites or
                  integrations. We are not responsible for the privacy practices
                  of those external platforms. Our inclusion of any third-party
                  link should not be considered an endorsement by Emerald Data
                  Solutions, and we in no way guarantee the security or the
                  privacy of any information provided by you to these third
                  parties. Users should review third-party privacy policies
                  before engaging with those services.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  17. International Data Transfers
                </h3>
                <p className="mb-4">
                  Our Services are primarily designed for use in the United
                  States. Should the geographic scope of our Services expand
                  beyond the United States, data may be stored and processed in
                  other countries where we or our third-party partners maintain
                  operations, and we will comply with applicable laws governing
                  cross-border data transfers. We do not knowingly store,
                  process, or transfer data to countries that are subject to
                  U.S. trade sanctions or restrictions.
                </p>
                <p className="mb-4">
                  Our Services are not currently intended for users in the
                  European Union or European Economic Area. If we expand to
                  these regions, we will comply with the General Data Protection
                  Regulation and provide additional rights to users, including
                  the right to data portability, erasure, and rectification.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  18. Privacy of Minors and Children
                </h3>
                <p className="mb-4">
                  Our Services are designed for adult and business use. Our
                  Services are not intended for minors or children. However,
                  some users, such as educational institutions, may use our
                  Services to share and disclose the data of minors and children
                  (such as student data, name, grade level, and assigned assets)
                  for asset tracking. We comply with applicable U.S. privacy
                  laws, including the Family Educational Rights and Privacy Act,
                  Children's Online Privacy Protection Act, and California
                  Consumer Privacy Act. If Services are expanded outside the
                  United States, we will comply with the laws and protections
                  required in those countries. Users are responsible for
                  ensuring compliance with local laws when using the Services in
                  their respective jurisdictions. We do not knowingly collect
                  personal information directly from minors or children.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  19. Changes to This Privacy Policy
                </h3>
                <p className="mb-4">
                  We may update this Privacy Policy periodically. The "Effective
                  Date" at the top indicates the most recent revision. Continued
                  use of the Services after the Effective Date specified
                  constitutes acceptance of the revised Privacy Policy and any
                  changes thereto.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  20. Dispute Resolution
                </h3>
                <p className="mb-4">
                  Any disputes arising out of or relating to this Privacy Policy
                  or the use of the Services shall be governed by the laws of
                  the State of Ohio, with the jurisdiction of any legal,
                  administrative, or arbitral proceeding being Erie County,
                  Ohio, and in any state or federal court located in Erie
                  County, Ohio.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  21. Mandatory Arbitration
                </h3>
                <p className="mb-4">
                  Any disputes arising out of or relating to this Privacy Policy
                  or the use of the Services shall be resolved exclusively
                  through binding arbitration conducted in Erie County, Ohio, in
                  accordance with the rules of the American Arbitration
                  Association.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">
                  22. Class Action Waiver
                </h3>
                <p className="mb-4">
                  Users waive any right to bring claims as a class, collective,
                  or representative action. Users further agree that disputes
                  shall be handled individually and not consolidated with
                  others.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">23. Non-Waiver</h3>
                <p className="mb-4">
                  The failure of Emerald Data Solutions to enforce any provision
                  of this Privacy Policy shall not be construed as a waiver of
                  such provision or any other rights and shall not affect the
                  right of Emerald Data Solutions to enforce such provision at
                  any time in the future.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">24. Severability</h3>
                <p className="mb-4">
                  If any provision of this Privacy Policy is found to be
                  invalid, illegal, or unenforceable by a court of competent
                  jurisdiction, the remaining provisions shall remain in full
                  force and effect. Any such provision shall be modified to the
                  extent necessary to make it valid and enforceable while
                  preserving the original intent. If modification is not
                  possible, the provision shall be deemed severed, and the
                  remaining provisions shall be interpreted as if the severed
                  provision had never been included.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-semibold mb-3">25. Contact Us</h3>
                <p className="mb-4">
                  For questions, concerns, or requests related to this Privacy
                  Policy, contact us at:
                </p>
                <div className="bg-gray-50 p-6 rounded-lg text-center mb-8">
                  <h3 className="text-xl font-bold mb-4">
                    Emerald Data Solutions
                  </h3>
                  <p className="text-gray-700 mb-2">
                    6715 Portland Road, Sandusky, OH, 44870
                  </p>
                  <a
                    href="mailto:support@emeralddata.io"
                    className="text-blue-600 hover:text-blue-800 underline mb-2 block"
                  >
                    support@emeralddata.io
                  </a>
                  <p className="text-gray-700">724-884-3596</p>
                </div>
              </section>

              <section>
                <p className="mb-4 font-medium">
                  This Privacy Policy is designed to comply with the privacy
                  requirements of mobile application distribution platforms. Our
                  data handling practices align with the guidelines established
                  for mobile application privacy and security. By using our
                  Services, you acknowledge that we process and protect data in
                  compliance with applicable platform policies and legal
                  requirements.
                </p>
              </section>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
